import { gsap, ScrollTrigger, smoothScrollObj } from "../layout";

// bootstrap collapse
import Collapse from 'bootstrap/js/dist/collapse';
// swiper
import Swiper from 'swiper';
import { Pagination, Navigation, Autoplay, Thumbs, EffectFade } from 'swiper/modules';
// matchheight
import matchHeight from 'jquery-match-height';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

function homeBannerSection() {
    const homeDescriptionSwiper = new Swiper(".home-banner__swiper-image", {
        modules: [ EffectFade ],
        speed: 1000,
        effect: "fade",
        allowTouchMove: false,
    })
    const homeImageSwiper = new Swiper(".home-banner__swiper-description", {
        modules: [ Navigation, Autoplay, Thumbs ],
        spaceBetween: 30,
        speed: 1000,
        loop: true,
        allowTouchMove: false,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        navigation: {
            prevEl: ".home-banner__prev",
            nextEl: ".home-banner__next",
        },
        thumbs: {
            swiper: homeDescriptionSwiper,
        },
    });

    // scroll to bottom
    const scrollBottom = document.querySelector('.home-banner__scroll-bottom');
    scrollBottom.addEventListener('click', () => {
        gsap.to(window, { duration: 0.4, scrollTo: { y: `#about` } });
    })
}

function reasonSection() {
    const reasonSwiper = new Swiper(".reason__swiper", {
        modules: [ Navigation ],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 450,
        allowTouchMove: false,
        navigation: {
            prevEl: ".reason__prev",
            nextEl: ".reason__next",
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        }
    });

    $('.reason-card__description').matchHeight();  
}

function serviceSection() {
    const collapseElements = document.querySelectorAll('.collapse');
    const accordionItems = document.querySelectorAll('.service__accordion-item');
    const serviceSwiper = new Swiper(".service__swiper", {
        modules: [ Autoplay, EffectFade ],
        speed: 500,
        effect: "fade",
        allowTouchMove: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        on: {
            init: () => {
                if (window.innerWidth >= 992) {
                    const collapseList = new Collapse(collapseElements[0], {
                        toggle: false
                    });
                    collapseList.show();
    
                    accordionItems[0].classList.add('fromLeft');
                }
            },
            transitionStart: () => {  
                if (window.innerWidth >= 992) {
                    const collapseList = new Collapse(collapseElements[serviceSwiper.activeIndex], {
                        toggle: false
                    });
                    collapseList.show();
                    
                    accordionItems.forEach(el => {
                        el.classList.add('fromLeftCollapsed');
                        el.classList.remove('fromLeft');
                    })
    
                    accordionItems[serviceSwiper.activeIndex].classList.add('fromLeft');
                    accordionItems[serviceSwiper.activeIndex].classList.remove('fromLeftCollapsed');
                }              
            }
        }
    });
    
    if (window.innerWidth < 992) {
        serviceSwiper.autoplay.stop();
    }

    const accordionButtons = document.querySelectorAll('.service__accordion-button');
    accordionButtons.forEach((el) => {
        el.addEventListener('click', (e) => {
            let accordionId = el.getAttribute('data-id');
            serviceSwiper.slideTo(accordionId, 500, true);
        })
    })
}

function capacityCountSection() {
    const capacitySection = document.querySelectorAll('.capacity');
    const items = document.querySelectorAll(".capacity__number");

    gsap.from(items, {
        textContent: 0,
        duration: 2,
        ease: "power1.in",
        snap: { textContent: 0.01 },
        stagger: {
            each: 0,
            onUpdate: function() {
                this.targets()[0].innerHTML = numberWithCommas(this.targets()[0].textContent);
            },
        },
        scrollTrigger: {
            trigger: capacitySection,
            start: "top center",
            // markers: true,
        },
    });

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

function newsHighlightSection() {
    const newsHighlight = new Swiper(".news-highlight__swiper", {
        modules: [ Pagination ],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 450,
        // allowTouchMove: false,
        pagination: {
            el: ".news-highlight__pagination",
            clickable: true,
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        }
    });
}
function newsHighlightSectionResponsive() {
    const newsHighlightHeader = document.querySelector('.news-highlight__header');
    const newsHighlightContainer = document.querySelector('.news-highlight .theme-container');
    const newsHighlightButton = document.querySelector('.news-highlight__button');

    if (window.innerWidth < 576) {
        newsHighlightContainer.append(newsHighlightButton);
    } else {
        newsHighlightHeader.append(newsHighlightButton);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    homeBannerSection();
    reasonSection();
    serviceSection();
    capacityCountSection();
    newsHighlightSection();
    newsHighlightSectionResponsive();
})

window.addEventListener('resize', () => {
    newsHighlightSectionResponsive();
})
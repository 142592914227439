// importing other Bootstrap Javascript Modules
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';

import { gsap } from "gsap";
// importing GSAP plugins
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

let smoother;
function smoothScroll() {
    if(window.innerWidth >= 992) {
        smoother = ScrollSmoother.create({
            smooth: 0.8,
            effects: true,
        });
    }
}

function fadeUpOnScroll() {
    const fadeUpEl = document.querySelectorAll('.fade-up');
    fadeUpEl.forEach((item) => {
        gsap.to(item, {
            y: 0,
            autoAlpha: 1,
            duration: 0.8,
            ease: "ease",
            scrollTrigger: {
                trigger: item,
                start: "top-=100 bottom-=15%",
                // markers: true,
            },
        })
    })
}

function stopSmoothScrollOnModal() {
    const scrollbarWidth = (window.innerWidth - document.body.clientWidth) + 'px';
    const customNavbar = document.querySelector('.navbar');

    document.addEventListener('show.bs.modal', () => {
        smoother.kill()
        ScrollTrigger.killAll()
        if (customNavbar) {
            customNavbar.style.paddingRight = scrollbarWidth
        }
    })
    
    document.addEventListener('hidden.bs.modal', () => {
        smoothScroll();
        fadeUpOnScroll();
        if (customNavbar) {
            customNavbar.style.paddingRight = null
        }
    })
}

function navbarSection() {
    let dropdownItem = document.querySelectorAll('.navbar__nav-item.dropdown');
    dropdownItem.forEach((el) => {
        let itemToggle = el.querySelector('.dropdown-toggle.navbar__nav-link');

        if(itemToggle && window.innerWidth >= 992) {
            el.addEventListener('mouseenter', (ev) => {
                itemToggle.classList.add('show');
                itemToggle.setAttribute('aria-expanded', 'true');
                itemToggle.nextElementSibling.classList.add('show');
                itemToggle.nextElementSibling.setAttribute('data-bs-popper', 'static');
            })
            el.addEventListener('mouseleave', (ev) => {
                itemToggle.classList.remove('show');
                itemToggle.setAttribute('aria-expanded', 'false');
                itemToggle.nextElementSibling.classList.remove('show');
                itemToggle.nextElementSibling.removeAttribute("data-bs-popper");
            })
        }
    })
}

function navbarLanguageSection() {
    const navRight = document.querySelector('.navbar__nav-right');
    const navRightMobile = document.querySelector('.navbar__nav-right-mobile');
    const languageSwitcher = document.querySelector('.navbar__nav--language');

    if (window.innerWidth < 992) {
        navRightMobile.prepend(languageSwitcher);
    } else {
        navRight.prepend(languageSwitcher);
    }
}

function scrollByUrl() {
    const queryUrl = window.location.href; // GET QUERY URL
    let urlString = new URL(queryUrl);
    let sectionIdParam = urlString.searchParams.get("section");

    if (sectionIdParam) {
        setTimeout(() => {
            gsap.to(window, { duration: 0.4, scrollTo: { y: `#${sectionIdParam}`, offsetY: 50 } });
        }, 600);
    }
}

function floatingButtonOnScroll() {
    const scrollTop = document.querySelector('.floating-button');

    // get window top
    const doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (top == 0) {
        scrollTop.classList.remove('show');
    } else {
        scrollTop.classList.add('show');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    smoothScroll();
    stopSmoothScrollOnModal();
    fadeUpOnScroll();
    navbarSection();
    navbarLanguageSection();
    scrollByUrl();
});

window.addEventListener('resize', () => {
    navbarLanguageSection();
})

document.addEventListener('scroll', () => {
    floatingButtonOnScroll();
});

export { gsap, ScrollTrigger, ScrollSmoother, smoother, Modal }